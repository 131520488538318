<template>
  <div>
    <h2>Login</h2>
    <form @submit.prevent="login">
      <label for="email">Email:</label>
      <input type="email" id="email" v-model="email" required>
      <label for="password">Senha:</label>
      <input type="password" id="password" v-model="password" required>
      <button type="submit">Login</button>
    </form>
    <p v-if="error" class="error">{{ error }}</p>
  </div>
</template>

<script>
export default {
  data() {
    return {
      email: '',
      password: '',
      error: ''
    };
  },
  methods: {
    login() {
  // Aqui você pode adicionar a lógica de login, como fazer uma requisição para um servidor
  if (this.email === 'user@example.com' && this.password === 'password') {
        localStorage.setItem('isAuthenticated', 'true'); // Marcar como autenticado
        this.$router.push('/admin/home'); // Redirecionar para o painel de administração
      } else {
        this.error = 'Email ou senha incorretos';
      }
    }
  }
};
</script>

<style scoped>
.error {
  color: red;
}
</style>
