// router/index.js

// import Vue from 'vue';
// import VueRouter from 'vue-router';
import LoginForm from '../components/LoginForm.vue'; // Importe o componente de login
import HelloWorld from '../components/HelloWorld.vue';
import NotFound from '../components/NotFound.vue';
import AdminHome from '../components/admin/AdminHome.vue'; // Componente de Admin


// Vue.use(VueRouter);

import { createRouter, createWebHistory } from 'vue-router'; // Importe createRouter e createWebHistory


// const routes = [
//   {
//     path: '/login',
//     name: 'LoginForm',
//     component: LoginForm // Registre o componente de login com a rota '/login'
//   },
//   {
//     path: '/',
//     name: 'HelloWorld',
//     component: HelloWorld 
//   },
//   // Outras rotas do seu aplicativo
// ];

const router = createRouter({
    history: createWebHistory(),
    routes : [
        {
          path: '/login',
          name: 'LoginForm',
          component: LoginForm // Registre o componente de login com a rota '/login'
        },
        {
          path: '/',
          name: 'HelloWorld',
          component: HelloWorld 
        }
        ,{ path: '/admin/home', component: AdminHome, meta: { requiresAuth: true } }
        ,{ path: '/:pathMatch(.*)*', name: 'NotFound', component: NotFound }
    ]
    });


      
      // Adicione uma verificação global antes de cada navegação
      router.beforeEach((to, from, next) => {
        const isAuthenticated = localStorage.getItem('isAuthenticated'); // Verifique a autenticação
        if (to.matched.some(record => record.meta.requiresAuth) && !isAuthenticated) {
          next('/login'); // Redirecione para a página de login se não estiver autenticado
        } else {
          next(); // Caso contrário, prossiga para a rota desejada
        }
      });

export default router;
