<template>
  <div class="admin-dashboard">
    <nav>
      <ul>
        <li><router-link to="/admin/instituicoes">Instituições</router-link></li>
        <li><router-link to="/admin/arbitros">Árbitros</router-link></li>
        <li><router-link to="/admin/atletas">Atletas</router-link></li>
        <li><router-link to="/admin/categorias">Categorias</router-link></li>
        <li><router-link to="/admin/posicoes">Posições</router-link></li>
        <li><router-link to="/admin/status-campeonato">Status de Campeonato</router-link></li>
      </ul>
    </nav>
    <button @click="logout">Logout</button>
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'AdminHome',
  methods: {
    logout() {
      localStorage.removeItem('isAuthenticated');
      this.$router.push('/');
    }
  }
};
</script>

<style scoped>
.admin-dashboard {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px;
}

nav ul {
  list-style-type: none;
  padding: 0;
}

nav li {
  margin: 10px 0;
}

nav a {
  text-decoration: none;
  color: #3498db;
}

nav a:hover {
  text-decoration: underline;
}

button {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #e74c3c;
  color: white;
  border: none;
  cursor: pointer;
}

button:hover {
  background-color: #c0392b;
}
</style>
