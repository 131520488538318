<template>
  <div class="container">
    <header>
      <h1>Bem-vindo ao SportStats</h1>
      <nav>
        <ul>
          <li><router-link to="/login">Login</router-link></li>
          <li><router-link to="/register">Registro</router-link></li>
        </ul>
      </nav>
    </header>
    <main>
      <section id="institution-selection">
        <h2>Selecione sua Instituição</h2>
        <ul>
          <li v-for="institution in institutions" :key="institution.id">
            <router-link :to="'/institution/' + institution.id">{{ institution.name }}</router-link>
          </li>
        </ul>
      </section>
    </main>
    <footer>
      <p>&copy; 2024 SportStats. Todos os direitos reservados.</p>
    </footer>
  </div>
</template>

<script>
export default {
  data() {
    return {
      institutions: [
        { id: 1, name: 'Instituição 1' },
        { id: 2, name: 'Instituição 2' },
        { id: 3, name: 'Instituição 3' }
      ]
    };
  }
};
</script>

<style scoped>
.container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

header {
  background-color: #333;
  color: white;
  padding: 10px 20px;
}

nav ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

nav ul li {
  display: inline-block;
  margin-right: 20px;
}

nav ul li a {
  color: white;
  text-decoration: none;
}

main {
  padding: 20px;
}

section {
  margin-bottom: 20px;
}

footer {
  text-align: center;
  padding: 10px 0;
  background-color: #333;
  color: white;
}
</style>
